import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import SimpleNavar from './../components/SimpleNavbarComponent/SimpleNavbar';
import Footer from './../components/FooterComponent/Footer';

class Privacy extends Component {
  state = {};
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tokinfo | Express Anything With Numbers</title>
        </Helmet>
        <SimpleNavar />
        <div className="navbar-bg-with-bottom"></div>
        <div className="privacy-wrapper pt-5">
          <div className="container">
            <h2>TOKINFO PRIVACY POLICY</h2>
            <h4>Last Updated: June 15, 2020</h4>
            <ol>
			<li>
				<h3><u>Overview of Privacy Policy</u></h3>
					<p>Welcome to the Tokinfo Privacy Policy (<b>“Privacy Policy”</b>), which describes all the actions taken 
					on the information DevSpike LLC (herein as <b>“Tokinfo”</b> or <b>“we”</b> or <b>“us”</b> or <b>“our”</b>) collects about 
					you when you use the website (<a href="http://www.tokinfo.com">www.tokinfo.com</a>) and theTokinfo mobile app (collectively, <b>“Tokinfo”</b>).
					</p>

					<p>When you access and use Tokinfo, certain information is collected – through tracking software – 
					which is used to provide the services, improve your user experience, and make Tokinfo function 
					(our <b> “Services”</b>).</p>

					<p>This Privacy Policy further explains the types of information Tokinfo collects, how such information
					is collected, how such information is used, how such information may be disclosed, and how any
					message you posted on Tokinfo is secured.</p>

					<p>Tokinfo is provided to you (the user) from our base of operation in the United States; therefore, be 
					aware that any information we collect from you is processed from and stored on our server in the
					United States. </p>

					<p><b>By using Tokinfo, you hereby consent to the manner in which we collect and use your information 
					as described in this Privacy Policy. If you do not consent to this Privacy Policy, your only option is
					to stop using Tokinfo.</b></p>
			</li>


			<li>
				<h3><u>The Information Tokinfo Collects and Why</u></h3>
					<p>At the moment, Tokinfo does not require any user to submit any personal information, such as name, 
					email address, or phone number in order to access and use Tokinfo. However, Tokinfocollects analytical
					information, which helps us identify the anonymous number of users using Tokinfo and their sources.
					Analytical information is collected through Google Analytics and Apple/Google Play Stores from downloads.
					Analytical information is anonymous information and does not reveal any personal information about a
					specific user. We collect analytical information in order to identify how many users use Tokinfo and
					their source to Tokinfo.</p>
			</li>
			<li>
				<h3><u>The Disclosures of your Information</u></h3>
					<p>Any information you generate or pass on Tokinfo is visible only by you and anyone you give your  
					generated token to. However, there are certain categories of people who may also have access to
					that information:</p>
					<ul>
						<li><p><b>Third Party Service Providers:</b> Third party service providers are companies (or contractors)
						that carry out certain duties on our behalf. For example, any information generated through 
						Tokinfo is stored in Dynamo DB in Amazon Web Services. Therefore, any employee tasked with 
						handling Dynamo DB may have access to such information. However, Amazon has an obligation to
						keep any information from us confidential. In addition, Amazon ensures all its employees – 
						through NDA – abides by this same obligation(to keep information confidential).</p></li>
						<li><p><b>Employees of Tokinfo:</b>Tokinfo has employees for various tasks in relation to the Services.We  
						have a developer/server administrator as an employee that handles tasks related to Tokinfo and  
						the servers. Such an employee may have access to any information generated through Tokinfo.
						Like contractors, employees also have the obligation to keep any information confidential 
						(through the signing of an NDA).</p></li>
						<li><p><b>Law Enforcement:</b> We may disclose any generated information to law enforcement in the 
						event such disclosure is required by applicable law enforcement for investigative purposes of 
						illegal activities, such as fraud, government takeover, coup, and other illegal uses of Tokinfo. 
						We may also disclose any information if such disclosure will protect our rights and properties. 
						However, since information generated through Tokinfo has a maximum retention period of 48
						hours,the ability to obtain such information (if subject to this clause) will be possible within 48 
						hours of the user generating the information.</p> </li>
						<li><p><b>Anyone with a Token Number:</b> Any token number generated through Tokinfo contains any 
						information you converted to such a token number. If you give such a token number to anyone, 
						they may be able to access the message contained in it when they use it on Tokinfo. Therefore,
						you should only give out the token number to whoever you intend to pass the message or  
						information in it to.</p> </li>
					
					</ul>
				</li>
				
			
			<li>
				<h3><u>The Security of information</u></h3>
					<p>The purpose of Tokinfo is to create the private passing of personal information from one user to
					another; therefore, any information you generate a token number for through Tokinfo is secure. 
					For one, any request to generate information on Tokinfo is protected through SSL Encryption. This
					means that the information passed is encrypted and is not visible to anyone other than you.Secondly,
					the information is stored using Amazon DB of Amazon Web Services, which is a trusted third party 
					service provider in information storage. Furthermore, we use Google Recaptcha,service provided by
					Google, which prevents third parties from using any robots or extracting tool to attempt all possible 
					numbers and gain access to any information. Also, any token number generated automatically expires 
					48 hours after the token is generated.</p>

					<p>However, information security – no matter the technology used – may not always be effective. You are
					aware that no matter the technology used, it may not be enough to fully counter every threat or attempt 
					to access any information. Although we try to secure information as possible as can be, we cannot 
					guarantee it will always be secure. We will not be liable for breaches from theft.</p>

					<p>You also have an obligation to keep any token number generated confidential at all times. Ensure the 
					token number is only passed to anyone the information is intended. </p>
			</li>
			<li>
				<h3><u>The Retention Period of Information</u></h3>
					<p>We do not keep any information on our database for longer than is necessary. Any token generated  
					through Tokinfo has a 48-hour life span. This means, for example, if you generate a token number now,  
					it has 48 hours before the message in it is automatically deleted from our server. Alternatively, you  
					may choose other expiry periods, which are 12 and 24 hours. All three options are available when you  
					generate a token number. </p>
			</li>
			<li>
				<h3><u>Third Party Adverts</u></h3>
					<p>Due to the presence of Google AdSense, certain parts of Tokinfo may contain adverts from other websites  
					(<b>“Third Party Adverts”</b>). Third Party Adverts are NOT affiliated to Tokinfo – neither does this Privacy  
					Policy govern them. When/if you access a Third Party Advert through Tokinfo, any information you submit  
					to the corresponding website will be governed by such a website’s privacy policy. You hereby release 
					Tokinfo from any loss, claims, damages, or expenses you may suffer from your access of Third Party  
					Adverts through Tokinfo.</p>
			</li>  
			<li>
				<h3><u>Changes to this Privacy Policy</u></h3>
					<p>We may review and change a portion of this Privacy Policy when there are changes in law or a feature on 
					Tokinfo. Any changes made to this Privacy Policy will be effective upon a change in the last updated date 
					above. You are always required to review this Privacy Policy for any changes.</p>
			</li>
			<li>
				<h3><u>Contact Us</u></h3>
					<p>For any questions or complaints about Tokinfo, reach out to us via the information below:</p>
					
					<h3>DevSpike LLC</h3>
					
					<i>1309 Coffeen Avenue STE 1200</i><br/>
					<i>Sheridan, Wyoming 82801</i><br/>
					<i>+1 (989) 317-2229 (USA)</i><br/>
					<i>+8801711731169 (WhatsApp)</i><br/>
			</li>
		</ol>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Privacy;
